<template lang="">
<div>
    <MDBTable hover striped responsive >
        <tbody >
            <tr v-for="item in Object.keys(data)" :key="item">
                <th scope="col">{{item}}</th>
                <td scope="col">{{isObjectGetId(data[item])}}</td>
            </tr>
        </tbody>

    </MDBTable>
</div>
</template>

<script>
import {
    MDBTable
} from 'mdb-vue-ui-kit';

export default {
    props: {
        data: Object
    },
    components: {
        MDBTable,
    },
    methods: {
        isObjectGetId(val){
            if(typeof(val)=='object' && val!=null){
                return val.text;
            }
            return val;
        }
    },
};
</script>

<style scoped>
tbody{
    text-align: left !important;
}

.table>:not(caption)>*>* {
    padding: 0.25rem 1.4rem  !important;
}
</style>
