<template lang="">
<div>
    <MDBCard text="center" class="col-md-8 col-lg-6 bg-light p-3 rounded mx-auto align-items-center" style="margin-top:80px;">
        <MDBCardHeader style="background-color:#1266F1; font-size:30px; color:#fff; margin-left:40px;margin-right:40px; height:80px; transform:translateY(-60px); position:absolute; width:calc(100% - 40px);box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;">
            Şifre Oluşturma
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <MDBRow tag="form" class="p-0 needs-validation" validate @submit.prevent="setPassword">
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Kimlik Numarası" type="text" v-model="identificationNumber" validationEvent="input" invalidFeedback="Kontrol ediniz" required minLength="4" />
                </MDBCol>
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Aktivasyon Kodu" type="text" v-model="activationCode" validationEvent="input" invalidFeedback="Kontrol ediniz" required minLength="4" />
                </MDBCol>
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Yeni Şifre" type="password" v-model="password" validationEvent="input" invalidFeedback="Kontrol ediniz" required minLength="4" />
                </MDBCol>
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Yeni Şifre Tekrar" type="password" v-model="repassword" invalidFeedback="Kontrol ediniz" required validationEvent="input" minLength="4" />
                </MDBCol>
                <MDBRow class="justify-content-end p-0 m-0">
                    <MDBCol md="4" class="p-1">
                        <MDBBtn color="primary" class="w-100" type="submit">Onayla</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBRow>
        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import {
    useRoute
} from "vue-router";
import Swal from "sweetalert2";
import axios from "axios";
import {
    MDBInput,
    MDBCardHeader,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
} from "mdb-vue-ui-kit";
export default {
    inject: ["BASE_API_URL"],
    components: {
        MDBInput,
        MDBCardHeader,
        MDBCardBody,
        MDBRow,
        MDBCol,
        MDBCard,
        MDBBtn,
    },
    data() {
        return {
            identificationNumber: null,
            activationCode: null,
            password: null,
            repassword: null,
            mail : null,
        };
    },
    created() {
        const route = useRoute();
        this.identificationNumber = route.params.identificationNumber;
        this.mail = route.query.mail;
        this.$root.isLoading = false;
    },
    methods: {
        setPassword(e) {
            this.$root.isLoading = true;
            e.target.classList.add("was-validated");
            if(this.repassword==this.password){
                var formdata= new FormData();
                formdata.append('IdentificationNumber',this.identificationNumber);
                formdata.append('ActivationCode',this.activationCode);
                formdata.append('WebPassword',this.password);
                formdata.append('Mail',this.mail);
                axios
                    .post(
                        this.BASE_API_URL + "/Auth/SetPassword", 
                        formdata
                    )
                    .then((res) => {
                        this.$root.isLoading = false;
                        if (res.data.success==true) {
                            Swal.fire({
                            title: "Başarılı!",
                            text: res.data.message,
                            icon: "success",
                            confirmButtonText: "Tamam",
                            timer: 1300
                            });
                            setTimeout(() => {
                                this.$router.push('/');
                            }, 500);
                        }
                        else{
                            Swal.fire({
                                title: "Hata!",
                                text: res.data.message,
                                icon: "error",
                                confirmButtonText: "Tamam"
                            });
                        }
                    })
                    .catch((err) => {
                        this.$root.isLoading = false;
                        console.log("Set Password: ", err)
                    });
            }
        },
    },
};
</script>

<style lang="">
</style>
