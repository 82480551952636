<template lang="">
    <div>
        <ExternalPaymentHome :filter="filter"></ExternalPaymentHome>
    </div>
</template>
<script>
import ExternalPaymentHome from "../components/Home/ExternalPaymentHome.vue";
import { useRoute } from "vue-router";
export default {
  components: {
    ExternalPaymentHome,
  },
  data() {
    return {
      filter: 0,
    };
  },
  created() {
    document.title = this.$route.name;
    const route = useRoute();
    this.filter = route.params.filter;
  },
};
</script>
<style lang="">
</style>