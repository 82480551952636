<template lang="">
<div>
    <MDBCard text="center" style="width:auto; max-width:600px" class="bg-light p-0 mt-1 rounded mx-auto">
        <MDBCardBody class="mt-0 w-100">
            <MDBRow class="p-0 needs-validation">
                <KeyValueTable :data="userDetail"></KeyValueTable>
            </MDBRow>
            <MDBRow class="p-0 needs-validation">
                    <qrcode-vue class="mx-auto border border-dark p-3" :value="qrValue" :size="qrSize" level="H" />
            </MDBRow>
            <MDBRow class="p-1 needs-validation">
                <MDBBtn class="col-6 mx-auto" color="primary" v-on:click="gotoReturn()">Geri Dön</MDBBtn>
            </MDBRow>

        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import QrcodeVue from 'qrcode.vue';
import KeyValueTable from "../components/Tables/KeyValueTable.vue";
import {
    useRoute
} from "vue-router";
import {
    MDBCardBody,
    //   MDBCardTitle,
    MDBRow,
    MDBCard,
    MDBBtn
} from "mdb-vue-ui-kit";
export default {
    inject: ["BASE_API_URL"],
    components: {
        MDBCardBody,
        // MDBCardTitle,
        MDBRow,
        MDBCard,
        QrcodeVue,
        KeyValueTable,
        MDBBtn
    },
    data() {
        return {
            image: '/avatar.png',
            tryCount: 0,
            userDetail: {},
            qrCard: {},
            qrValue: "",
            qrSize: 200
        };
    },
    methods: {
        generateQr: function (id) {
            this.$root.isLoading = false;
            axios
                .get(this.BASE_API_URL + "/Cards/GetQr/" + id, {
                    headers: {
                        Authorization: localStorage.getItem("tokenData"),
                    },
                })
                .then((res) => {
                    if (res.data.success) {
                        this.qrValue = res.data.data;
                        // this.encodeQr(res.data.data);
                    }
                    else {
                        Swal.fire(
                            'İşlem Yapılamadı!',
                            'Bir hata ile karşılaşıldı.',
                            'warning'
                        )
                        this.qrValue = ""
                    }
                });
        },
        encodeQr: function (code) {
            var formData = new FormData();
            formData.append(
                "Code",
                code
            );
            this.$root.isLoading = false;
            axios
                .post(this.BASE_API_URL + "/Triggers/DecodeHash",formData, {
                    headers: {
                        Authorization: localStorage.getItem("tokenData"),
                    },
                })
                .then((res) => {
                    console.log(res);
                });
        },
        getCard: function (id) {
            axios
                .get(this.BASE_API_URL + "/Cards/GetById/" + id, {
                    headers: {
                        Authorization: localStorage.getItem("tokenData"),
                    },
                })
                .then((res) => {
                    if (res.data.success) {
                        this.qrCard = res.data.data;
                    }
                    else {
                        Swal.fire(
                            'İşlem Yapılamadı!',
                            'Bir hata ile karşılaşıldı.',
                            'warning'
                        )
                    }
                });
        },
        getEmployee: function () {
            axios
            axios
                .get(this.BASE_API_URL + "/Employees/Profile", {
                    headers: {
                        Authorization: localStorage.getItem("tokenData"),
                    },
                })
                .then((res) => {
                    this.$root.isLoading = false;
                    if (!res.data.success) {
                        if (res.data.message == "Expired token") {
                            localStorage.removeItem("tokenData");
                            Swal.fire({
                                title: "Oturum hatası!",
                                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                                icon: "warning",
                                confirmButtonText: "Tamam",
                                timer: 1300,
                            });
                            setTimeout(() => {
                                this.$router.push("/");
                            }, 500);
                            this.$root.navbarType = "login";
                        }
                    } else {
                        this.Name = res.data.data.Name;
                        this.SurName = res.data.data.SurName;
                        if (
                            res.data.data.PictureID &&
                            res.data.data.PictureID.split('"').length > 0
                        )
                            this.image = res.data.data.PictureID.split('"')[1];
                        if (res.data.data.DepartmentID)
                            this.Department = res.data.data.DepartmentID.text;
                        var data = res.data.data;
                        delete data.PictureID;
                        delete data.DepartmentID;
                        delete data.MobilePhone1;
                        delete data.Mail;
                        var fields = res.data.fields;
                        fields.forEach((element) => {
                            if (data[element.Field] != undefined) {
                                data[element.Name] = data[element.Field];
                                delete data[element.Field];
                            }
                        });
                        data["Tag Kodu"]=this.qrCard.TagCode;
                        data["Formatlandı mı?"]=this.qrCard.isDefined?"Hayır":"Evet";
                        data["Formatlanma Tarihi"]=this.qrCard.DefinedTime;
                        data["Kart Durumu"]=this.qrCard.Status;
                        console.log(data);
                        this.userDetail = data;
                    }
                })
                .catch((err) => console.log("Profile: ", err));
        },
        gotoReturn: function(){
            this.$router.push("/cards");
        },
    },
    created() {
        this.$root.navbarType = "login";
        this.$root.isLoading = true;
        document.title = this.$route.name;
        const route = useRoute();
        var id = route.params.cardid;
        var obj = this;
        this.getCard(id)
        this.getEmployee()
            this.generateQr(id)
        setInterval(() => {
            obj.generateQr(id)
        }, 15000)
    },
    mounted() { },
};
</script>

<style scoped>
img {
    height: 100px !important;
    width: 100px !important;
    object-fit: cover;
}
</style>
