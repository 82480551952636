import { createWebHistory, createRouter } from "vue-router";
// import Home from "@/pages/Home.vue";
import Login from "@/pages/Login.vue";
import PaymentType from "@/pages/PaymentType.vue";
import ExternalPayment from "@/pages/ExternalPayment.vue";
import Dashboard from "@/pages/Dashboard.vue";
import Payments from "@/pages/Payments.vue";
import Cards from "@/pages/Cards.vue";
import QrCard from "@/pages/QrCard.vue";
import NewAccount from "@/pages/NewAccount.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import AddBalance from "@/pages/AddBalance.vue";
import SetPassword from "@/pages/SetPassword.vue";
import PayBalance from "@/pages/PayBalance.vue";
import Profile from "@/pages/Profile.vue";
import PaymentResult from "@/pages/PaymentResult.vue";
import Hes from "@/pages/Hes.vue";
import Subscriptions from "@/pages/Subscriptions.vue";
import AddSubscription from "@/pages/AddSubscription.vue";
import UpdatePassword from "@/pages/UpdatePassword.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { transition: 'slide-left' }
  },
  {
    path: "/update-password",
    name: "Update Password",
    component: UpdatePassword,
    meta: { transition: 'slide-left' }
  },
  // {
  //   path: "/Login",
  //   name: "Login",
  //   component: Login,
  //   meta: { transition: 'slide-left' }
  // },
  {
    path: "/PaymentType/:filter",
    name: "Payment Select",
    component: PaymentType,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/ExternalPayment/:filter/:type",
    name: "Payment İnformation",
    component: ExternalPayment,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/payments",
    name: "Payments",
    component: Payments,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/subscriptions",
    name: "Abonelikler",
    component: Subscriptions,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/cards",
    name: "Kartlar",
    component: Cards,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/cards/qr/:cardid",
    name: "Qr Kart",
    component: QrCard,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/new-account",
    name: "NewAccount",
    component: NewAccount,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/set-password/:identificationNumber",
    name: "SetPassword",
    component: SetPassword,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/add-balance",
    name: "AddBalance",
    component: AddBalance,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/add-subscription",
    name: "AddSubscription",
    component: AddSubscription,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/pay-balance/:id",
    name: "PayBalance",
    component: PayBalance,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/hes",
    name: "Hes",
    component: Hes,
    meta: { transition: 'slide-right' }
  },
  {
    path: "/PaymentResult/:type/:message",
    name: "Payment Result",
    component: PaymentResult,
    meta: { transition: 'slide-right' }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
export default router;