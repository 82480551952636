<template lang="">
<div>
    <MDBCard v-if="!payVisible" text="center" class="col-md-8 col-lg-6 bg-light p-3 rounded mx-auto align-items-center" style="margin-top:80px;">
        <MDBCardHeader style="margin-left:40px;margin-right:40px; height:100px; transform:translateY(-80px); position:absolute; width:calc(100% - 40px);box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;">
            <img src="../assets/home_logo.png" class="img-fluid" style="height:80px;" />
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <!-- <div class=" col-md-8 col-lg-6 bg-light p-3 rounded mx-auto mt-3 align-items-center"> -->
            <MDBCardTitle>{{filteredData.Name}}</MDBCardTitle>
            <MDBCardText v-if="paymentData.Amount!=undefined">{{paymentData.Name}}</MDBCardText>
            <MDBCardText v-if="paymentData.Amount==undefined">Beyan İle Ödeme</MDBCardText>
            <MDBRow tag="form" class="p-0 needs-validation" validate @submit.prevent="checkForm">
                <MDBRow class="g-3">
                    <MDBCol md="12">
                        <MDBInput label="Ad Soyad" v-model="fullname" invalidFeedback="Kontrol ediniz" required />
                    </MDBCol>
                    <MDBCol md="12" class="mb-3">
                        <MDBInput inputGroup v-model="identityNumber" :formOutline="false" invalidFeedback="Kontrol ediniz" required>
                            <template #prepend>
                                <MDBDropdown v-model="identityToggle">
                                    <MDBDropdownToggle @click="identityToggle = !identityToggle" style="background-color: rgb(59, 89, 152); min-width:220px;">{{identityType}}</MDBDropdownToggle>
                                    <MDBDropdownMenu>
                                        <MDBDropdownItem href="#" @click="selected('TC Kimliği')">TC Kimlik</MDBDropdownItem>
                                        <MDBDropdownItem href="#" @click="selected('Pasaport')">Pasaport</MDBDropdownItem>
                                        <MDBDropdownItem href="#" @click="selected('Vergi Kimliği')">Vergi Kimliği</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </template>
                        </MDBInput>
                    </MDBCol>
                    <MDBCol md="6">
                        <MDBInput label="Telefon Numarası" v-model="phone" type="tel" invalidFeedback="Kontrol ediniz" required :onChange="maskPhone()" />
                    </MDBCol>
                    <MDBCol md="6">
                        <MDBInput label="E-posta Adresi" v-model="email" type="email" invalidFeedback="Kontrol ediniz" required />
                    </MDBCol>
                    <MDBCol md="9" sm="9">
                        <MDBInput label="Tutar" v-model="amount" type="text" :readonly="amountReadonly" invalidFeedback="Kontrol ediniz" required />
                    </MDBCol>
                    <MDBCol md="3" sm="3">
                        <MDBInput label="Küsürat" :maxlength="2" v-model="fraction" type="text" :readonly="amountReadonly"  invalidFeedback="Kontrol ediniz" required />
                    </MDBCol>
                    <MDBCol md="12">
                        <MDBTextarea rows="4" label="Açıklama" v-model="description" type="description" invalidFeedback="Kontrol ediniz" required />
                    </MDBCol>

                </MDBRow>

                <MDBRow class="justify-content-between mt-4">
                    <MDBCol md="6" sm="6">
                        <router-link :to="'/PaymentType/'+filter" class="btn btn-outline-indigo w-100 p-2 mt-1 ripple-surface" style="font-size:18px; border:2px solid rgb(76, 117, 163); color: rgb(76, 117, 163);" >Geri Dön</router-link>
                    </MDBCol>
                    <MDBCol md="6" sm="6">
                        <MDBBtn outline="primary" v-on:click="makePayment" color="primary" class="p-3 w-100" style=" color:#fff; background-color: rgb(59, 89, 152); border-color: rgb(59, 89, 152);" type="submit">Ödeme Yap</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBRow>

        </MDBCardBody>
        <!-- </div> -->
    </MDBCard>
    <PayBalanceWithCard v-if="payVisible" :payForm="payForm"></PayBalanceWithCard>
</div>
</template>

<script>
import { useRoute } from "vue-router";
import axios from "axios";
import { maska } from "maska";
import Swal from "sweetalert2";
import PayBalanceWithCard from "./PayBalanceWithCard.vue";
import { ref } from "vue";
import {
  MDBInput,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBDropdown,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBTextarea,
} from "mdb-vue-ui-kit";
export default {
  inject: ["BASE_API_URL"],
  directives: { maska },
  components: {
    MDBInput,
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBDropdown,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    PayBalanceWithCard,
    MDBTextarea,
  },
  data() {
    return {
      filter: 0,
      type: 0,
      fullname: null,
      identityType: "Kimlik Türünü Seçiniz",
      identityNumber: null,
      amount: undefined,
      phone: "",
      fraction:undefined,
      amountReadonly:true,
      description:"",
      email: null,
      paymentData: {},
      filteredData: {},
      payForm: {
        url: "",
        inputs: {},
      },
      payVisible: false,
    };
  },
  created() {
    document.title = this.$route.name;
    this.$root.isLoading = true;
    const route = useRoute();
    this.filter = route.params.filter;
    this.type = route.params.type;
    if (this.type != 0) {
      axios
        .get(
          this.BASE_API_URL +
            "/PaymentTypes/GetAll?Filter=" +
            this.filter +
            "&Id=" +
            this.type
        )
        .then((res) => {
          this.paymentData = res.data.data.data[0];
            this.amount=this.paymentData.Amount.split('.')[0];
            this.fraction=this.paymentData.Amount.split('.')[1];
            this.amountReadonly=true;

          if (res.data.data.data.length == 0 && this.type != 0) {
            window.location.href = "/";
          }
          this.$root.isLoading = false;
        })
        .catch((err) => console.log("Login: ", err));
    }
    else{
        this.amountReadonly=false;
    }
    axios
      .get(this.BASE_API_URL + "/PaymentTypes/GetAll?Id=" + this.filter)
      .then((res) => {
        this.filteredData = res.data.data.data[0];
        this.$root.isLoading = false;
      })
      .catch((err) => console.log("Login: ", err));
  },
  setup() {
    const identityToggle = ref(false);
    return {
      identityToggle,
    };
  },
  methods: {
    checkForm(e) {
      e.target.classList.add("was-validated");
    },
    selected(val) {
      this.identityType = val;
      this.identityToggle = false;
    },
    makePayment() {
      var formData = new FormData();
      formData.append("FullName", this.fullname);
      formData.append("IdentityType", this.identityType);
      formData.append("IdentificationNumber", this.identityNumber);
      formData.append("Phone", this.phone);
      formData.append("Email", this.email);
      formData.append("Filter", this.filter);
      formData.append("Amount", this.amount+""+this.fraction.padEnd(2,'0'));
      formData.append("Type", this.type);
      formData.append("Description", this.description);
      axios
        .post(this.BASE_API_URL + "/Banks/CreatePaymentForExternal", formData)
        .then((res) => {
          if (!res.data.success) {
            if (res.data.messages == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            } else {
              Swal.fire({
                title: "Hata!",
                text: res.data.message,
                icon: "warning",
                confirmButtonText: "Tamam",
              });
            }
          } else {
            this.payVisible = true;
            this.payForm = res.data.data;
          }
        })
        .catch((err) => console.log("External Payment: ", err));
    },
    maskPhone() {
      var maskedValue = "+__(___)-___-____";
      var mask = this.phone.replace(/[^0-9]/g, "");
      for (var i = 0; i < mask.length; i++) {
        maskedValue = maskedValue.replace("_", mask.charAt(i));
      }
      if (maskedValue.indexOf("_") != "-1") {
        maskedValue = maskedValue.substring(0, maskedValue.indexOf("_"));
      }
      this.phone = maskedValue;
    },
  },
};
</script>

<style lang="">
</style>
