<template lang="">
<div>
    <MDBCard text="left" class="col-md-8 col-lg-6 bg-light p-3 rounded mx-auto align-items-center" style="margin-top:80px;">
        <MDBCardHeader bg="info" style=" text-align:center; font-size:30px; color:#fff; margin-left:40px;margin-right:40px; height:80px; transform:translateY(-60px); position:absolute; width:calc(100% - 40px);box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;">
            Şifre Sıfırlama
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <MDBRow tag="form" class="p-0 needs-validation" validate @submit.prevent="resetPassword">
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Kimlik Numarası" type="text" v-model="identity" validationEvent="input" invalidFeedback="Kontrol ediniz" required />
                </MDBCol>
                <MDBRow class="justify-content-end p-0 m-0">
                    <MDBCol md="4" class="p-1">
                        <MDBBtn color="info" class="w-100" type="submit">Sıfırla</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBRow>
        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import {
    MDBInput,
    MDBCardHeader,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn
} from "mdb-vue-ui-kit";
export default {
    title: "NewAccount",
    inject: ["BASE_API_URL"],
    components: {
        MDBInput,
        MDBCardHeader,
        MDBCardBody,
        MDBRow,
        MDBCol,
        MDBCard,
        MDBBtn
    },
    data() {
        return {
            identity: ""
        };
    },
    created() {
        document.title = this.$route.name;
        this.$root.isLoading = true;
    },
    mounted() {
        this.$root.isLoading = false;
    },
    methods: {
        resetPassword(e) {
            this.$root.isLoading = true;
            e.target.classList.add("was-validated");
            var formData=new FormData();
            formData.append('IdentificationNumber',this.identity);
            axios
                .post(
                    this.BASE_API_URL + "/Auth/ResetPassword", 
                    formData
                )
                .then((res) => {
                    this.$root.isLoading = false;
                    if(res.data.success==true){
                        Swal.fire({
                            title: "Başarılı!",
                            text: res.data.message,
                            icon: "success",
                            confirmButtonText: "Tamam"
                        });
                        window.location.href="/set-password/"+this.identity;
                    }
                    else{
                        Swal.fire({
                            title: "Hata!",
                            text: res.data.message,
                            icon: "error",
                            confirmButtonText: "Tamam"
                        });
                    }
                })
                .catch((err) => {
                    this.$root.isLoading = false;
                    console.log("Reset Password: ", err)
                });
        },
        maskPhone() {
            var maskedValue = "+__(___)-___-____";
            var mask = this.phone.replace(/[^0-9]/g, '');
            for (var i = 0; i < mask.length; i++) {
                maskedValue = maskedValue.replace("_", mask.charAt(i));
            }
            if (maskedValue.indexOf("_") != "-1") {
                maskedValue = maskedValue.substring(0, maskedValue.indexOf("_"));
            }
            this.phone = maskedValue;
        }
    },
};
</script>

<style lang="">
</style>
