<template lang="">
<div style="width:100%;">
    <MDBCard text="center" class="col-md-8 col-lg-6 bg-light p-3 rounded mx-auto align-items-center" style="margin-top:150px;">
        <MDBCardHeader style="width: calc(100% - 80px); margin: 0 auto; height:100px; transform:translateY(-80px); position:absolute; width:calc(100% - 40px);box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;">
            <img src="../../assets/home_logo.png" class="img-fluid" style="height:80px;" />
        </MDBCardHeader>

        <MDBCardBody class="mt-4 w-100">
            <router-link :to="getUrl(item)" class="btn btn-outline-indigo w-100 p-2 mt-1 ripple-surface" style="font-size:18px; border:2px solid rgb(76, 117, 163); color: rgb(76, 117, 163);" v-for="item in buttons" :key="item"><div>{{item.Name}}</div><div>{{getMoney(item)}}</div></router-link>
        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import {
    MDBCard,
    MDBCardHeader,
    MDBCardBody
} from "mdb-vue-ui-kit";
import axios from "axios";
export default {
    components: {
        MDBCard,
        MDBCardHeader,
        MDBCardBody
    },
    props: {
        filter: {
            type: Number,
            default: 0
        },
    },
    inject: ["BASE_API_URL"],
    data() {
        return {
            info: null,
            buttons: [],
        };
    },
    created() {
        this.$root.isLoading=true;
    },
    mounted() {
        axios
            .get(this.BASE_API_URL + "/PaymentTypes/GetAll?Filter=" + this.filter)
            .then((res) => {
                this.buttons = res.data.data.data;
                if (this.filter != 0) {
                    this.buttons[this.buttons.length] = {
                        Name: "Beyan İle Ödeme",
                        to: "/ExternalPayment/" + this.filter + "/0",
                    };
                    this.buttons[this.buttons.length] = {
                        Name: "GERİ GÖN",
                        to: "/",
                    };
                }
                this.$root.isLoading = false;
            })
            .catch((err) => console.log("Login: ", err));
    },
    methods: {
        getUrl: function (val) {
            if (Object.prototype.hasOwnProperty.call(val, "to")) {
                return val.to;
            }
            if(val.LoginRequire.id==1){
              return '/login'
            }
            if (this.filter == 0) return "/PaymentType/" + val.Id;
            else return "/ExternalPayment/" + this.filter + "/" + val.Id;
        },
        getMoney: function (item) {
            if (item.Amount > 0) {
                return "(" + item.Amount+ " TL)";
            }
        },
    },
};
</script>

<style lang="">
</style>
