import 'mdb-vue-ui-kit/css/mdb.min.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'vue-loading-overlay/dist/vue-loading.css';
import VueAxios from 'vue-axios';
import Maska from 'maska';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
const app = createApp(App)
app.use(router, Maska, VueSweetalert2, VueAxios)
app.provide('BASE_API_URL', 'https://restapi.aku.edu.tr')
app.mount('#app')
// if(!window.location.href.startsWith("https")){
//     let url=window.location.href.replace(new RegExp("(http)", 'g'), "https");
//     window.location.href=url;
// }