<template lang="">
<div class="p-5">
    <MDBCard text="center" class="col-md-8 col-lg-6 bg-light p-3 rounded mx-auto align-items-center" style="margin-top:80px;">
        <MDBCardHeader class="z-depth-1 p-0" style="margin:-70px 40px 0px; background-color:#fff; width:100%; ">
    <img src="../assets/home_logo_afyon.png" class="m-2" style="height:80px;" alt="">
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <MDBRow tag="form" class="p-0 needs-validation" validate @submit.prevent="checkForm">
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Kullanıcı Adı" type="text" v-model="identity" validationEvent="input" invalidFeedback="Kontrol ediniz" required autocomplete="off" />
                </MDBCol>
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Şifre" type="password" v-model="password" invalidFeedback="Kontrol ediniz" required validationEvent="input" minLength="4" autocomplete="off"/>
                </MDBCol>
                <MDBRow class="justify-content-between p-0 m-0">
                    <MDBCol class="p-1">
                        <MDBBtn color="success" class="w-100" type="submit">Giriş</MDBBtn>
                    </MDBCol>
                </MDBRow>
                <MDBRow class="justify-content-between p-0 m-0" v-if="AuthType=='LDAP'">
                  <!-- <div class="col-md-4 col-sm-6 col-xs-12">
                        <a href="https://hesap.subu.edu.tr/" class="col btn btn-link p-1" >Şifremi unuttum</a>
                      </div> -->
                      <!--<div class="col-md-4 col-sm-6 col-xs-12">
                        <router-link to="/reset-password" class="col btn btn-link p-1" >Şifremi unuttum(Misafir)</router-link>
                      </div>
                       <div class="col-md-4 col-sm-6 col-xs-12">
                        <router-link to="/new-account" class="col btn btn-link p-1" >Yeni üyelik</router-link>
                      </div> 
                      <div class="col-md-3 col-sm-6 col-xs-12">
                        <a href="/kiosk.pdf" target="_blank" class="col btn btn-link p-1" >Kiosk kullanım kılavuzu</a>
                      </div>
                      <div class="col-md-3 col-sm-6 col-xs-12">
                        <a href="/sanalpos.pdf" target="_blank" class="col btn btn-link p-1" >Sanalpos kullanım kılavuzu</a>
                      </div>-->
                </MDBRow>
                <MDBRow class="justify-content-between p-0 m-0" v-if="AuthType=='Custom'">
                      <div class="col-md-3 col-sm-6 col-xs-12">
                        <router-link to="/reset-password" class="col btn btn-link p-1" >Şifremi unuttum</router-link>
                      </div>
                       <div class="col-md-3 col-sm-6 col-xs-12">
                        <router-link to="/new-account" class="col btn btn-link p-1" >Yeni üyelik</router-link>
                      </div> 
                      <div class="col-md-3 col-sm-6 col-xs-12">
                        <a href="/kiosk.pdf" target="_blank" class="col btn btn-link p-1" >Kiosk kullanım kılavuzu</a>
                      </div>
                      <div class="col-md-3 col-sm-6 col-xs-12">
                        <a href="/sanalpos.pdf" target="_blank" class="col btn btn-link p-1" >Sanalpos kullanım kılavuzu</a>
                      </div>
                </MDBRow>
                <MDBRow class="justify-content-between p-0 m-0" v-if="LoginAlertMessage.length>0">
                    <MDBCol class="p-1">
                      <p class="alert alert-danger">{{LoginAlertMessage}}</p>
                    </MDBCol>
                </MDBRow>
            </MDBRow>
        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import {
  MDBInput,
  MDBCardHeader,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
} from "mdb-vue-ui-kit";
export default {
  title: "Login",
  inject: ["BASE_API_URL"],
  components: {
    MDBInput,
    MDBCardHeader,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
  },
  data() {
    return {
      identity: "",
      password: "",
      AuthType: "Custom",
      LoginAlertMessage: ""
    };
  },
  created() {
    localStorage.removeItem("tokenData");
    // Swal.fire({
    //   title: "Uyarı!",
    //   text: 'Yeni sisteme giriş yapabilmek için öncelikle email adresiniz ile yeni üyelik oluşturmanız gerekmektedir!',
    //   icon: "warning",
    //   confirmButtonText: "Tamam"
    // });
    document.title = this.$route.name;
    this.$root.isLoading = true;
  },
  mounted() {
    var obj = this;
    axios
      .get(this.BASE_API_URL + "/Triggers/GetEnv")
      .then((res) => {
        obj.AuthType = res.data["SETTING.AuthType"];
        obj.LoginAlertMessage = res.data["SETTING.LoginAlertMessage"];
        this.$root.isLoading = false;
      })
      .catch((err) => console.log("Login: ", err));
    this.$root.isLoading = false;
  },
  methods: {
    checkForm(e) {
      e.target.classList.add("was-validated");
      var formData = new FormData();
      formData.append(
        "IdentificationNumber",
        this.identity.replaceAll(" ", "")
      );
      formData.append("Password", this.password);
      axios
        .post(this.BASE_API_URL + "/Auth/Login", formData, {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        })
        .then((res) => {
          this.$root.isLoading = false;
          if (res.data.success == true) {
            localStorage.setItem("tokenData", res.data.data);
            Swal.fire({
              title: "Başarılı!",
              text: res.data.message,
              icon: "success",
              confirmButtonText: "Tamam",
              timer: 1300,
            });
            setTimeout(() => {
              this.$router.push("/dashboard");
            }, 500);
            this.$root.navbarType = "dashboard";
          } else {
            Swal.fire({
              title: "Hata!",
              text: res.data.message,
              icon: "error",
              confirmButtonText: "Tamam",
              timer: 2000,
            });
          }
        })
        .catch((err) => console.log("Login: ", err));
    },
  },
  computed: {},
};
</script>

<style scoped>
.btn {
  float: left !important;
  font-size: 12px !important;
}

@media only screen and (max-width: 600px) {
  .btn {
    font-size: 10px !important;
  }
}

.z-depth-0 {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.z-depth-1 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.z-depth-1-half {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
    0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
}

.z-depth-2 {
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.z-depth-3 {
  -webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19) !important;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19) !important;
}

.z-depth-4 {
  -webkit-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21) !important;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22),
    0 25px 55px 0 rgba(0, 0, 0, 0.21) !important;
}

.z-depth-5 {
  -webkit-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22) !important;
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22) !important;
}

.disabled,
:disabled {
  pointer-events: none !important;
}
</style>
