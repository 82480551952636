<template lang="">
<div>
    <MDBCard text="left" class="col-md-8 col-lg-8 bg-light p-3 mt-3 rounded mx-auto" style="max-width:80%">
        <div class="card-header h4">Paket Bilgileri</div>
        <div class="card-body">
        <div class="pay-form-group">
            <div class="payAmount"><div>Bakiye</div><div>{{lastBalance}} ₺</div></div>
            <div class="text-danger" v-if="lastBalance<0">Minimum eksi bakiyeniz kadar yükleme yapabilirsiniz.</div>
            <div class="text-danger" v-if="message!='' && message!=null">*{{message}}</div>
            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <label>Kartınızı Seçiniz</label>
                        <select class="form-control" v-model="card" v-on:change="getPackage()">
                            <option v-for="item in cards" :key="item" :value="item">{{item.TagCode}} - {{item.CafeteriaGroupName??'Kafeyerya grubu yok'}} - {{item.CardDesc}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="accessZones.length>0">
                <div class="row">
                    <div class="col">
                        <label>Bölge Seçiniz</label>
                        <select class="form-control" v-model="accessZone">
                            <option v-for="item in accessZones" :key="item" :value="item.Id">{{item.ZoneName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="applications.length>0" v-on:change="getPackage()">
                <div class="row">
                    <div class="col">
                        <label>Öğün Seçiniz</label>
                        <select class="form-control" v-model="application">
                            <option v-for="item in applications" :key="item" :value="item.CafeteriaApplicationID">{{item.ApplicationName}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="subscriptionPackages.length>0" >
                <div class="row">
                    <div class="col">
                        <label>Paket seçiniz</label>
                        <select class="form-control" v-model="subscriptionPackage" v-on:change="changePackage()">
                            <option v-for="item in subscriptionPackages" :key="item" :value="item.Id">{{item.Name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="subscriptionPackage!=null" >
                <div class="row">
                    <div class="col">
                        <label>Tarih seçiniz</label>
                        <input class="form-control" type="date" v-model="startDate" :disabled="startDateDisable" v-on:change="getDetail()"/>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="dayCount>0" >
                <div class="row">
                    <div class="col">
                        <label>Günler </label>
                          <table class="table table-stripped">
                             <thead>
                                <tr>
                                  <th>Durum</th>
                                  <th>Tarih</th>
                                  <th>Gün</th>
                                  <th>Menü</th>
                                </tr>
                             </thead>
                             <tbody>
                                <tr v-for="day in days" :key="day" :style="day.selected?'':'background-color: #d52121; color:#fff;'">
                                  <td>
                                    <label class="switch">
                                      <input type="checkbox" :checked="day.selected" v-model="selectedDays" :value="day.date" v-on:change="syncPriceAndDay()">
                                      <span class="slider"></span>
                                    </label>
                                  </td>
                                  <td>{{day.date}}</td>
                                  <td>{{day.dayName}}</td>
                                  <td>{{day.menu.map(e=>e.ProductName).join(",")}}</td>
                                </tr>
                             </tbody>
                          </table>
                    </div>
                </div>
            </div>
            <div class="form-group" v-if="dayCount!=0" >
                <div class="row">
                    <div class="col">
                        <label>Gün Sayısı</label>
                        <input class="form-control" type="number" v-model="dayCount" disabled/>
                    </div>
                </div>
            </div>
            <!-- <div class="form-group" v-if="dailyPrice!=0" >
                <div class="row">
                    <div class="col">
                        <label>Günlük Fiyat</label>
                        <input class="form-control" type="number" v-model="dailyPrice" disabled/>
                    </div>
                </div>
            </div> -->
            <div class="form-group" v-if="totalPrice!=0" >
                <div class="row">
                    <div class="col">
                        <label>Paket Ücreti</label>
                        <input class="form-control" type="number" v-model="totalPrice" disabled/>
                    </div>
                </div>
            </div>
            <div class="form-group mt-3 row justify-content-end" style="padding:0px 13px">
                  <button class="btn col-sm-12 col-md-6" v-on:click="addBalance()"> Yükle</button>
            </div>
        </div>
        </div>
        
    </MDBCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { maska } from "maska";
import { MDBCard } from "mdb-vue-ui-kit";
export default {
  inject: ["BASE_API_URL"],
  directives: { maska },
  components: {
    MDBCard,
  },
  data() {
    return {
      tryCount: 0,
      modalStatus: true,
      lastBalance: 0,
      cards: [],
      accessZones: [],
      subscriptionPackages: [],
      applications: [],
      accessZone: null,
      subscriptionPackage: null,
      application: null,
      card: null,
      startDate: null,
      totalBalance: 0,
      dayCount: 0,
      dailyPrice: 0,
      totalPrice: 0,
      days: [],
      selectedDays:[],
      startDateDisable: false,
      message: ""
    };
  },
  methods: {
    addBalance: function () {
      if (this.startDate != null) {
        if(this.selectedDays.length==0){
          Swal.fire({
            title: "Oturum hatası!",
            text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
            icon: "warning",
            confirmButtonText: "Tamam",
            timer: 1300,
          });
          return;
        }
        var formData = new FormData();
        formData.append("TagCode", this.card.TagCode);
        formData.append("ApplicationID", this.application);
        formData.append("PackageID", this.subscriptionPackage);
        formData.append("StartDate", this.startDate);
        formData.append("CafeteriaGroupID", this.card.CafeteriaGroupID);
        formData.append("AccessZoneId", this.accessZone);
        formData.append("SelectedDays", this.selectedDays);
        axios
          .post(
            this.BASE_API_URL + "/SubscriptionEvents/AddForClient",
            formData,
            {
              headers: { Authorization: localStorage.getItem("tokenData") },
            }
          )
          .then((res) => {
            if (!res.data.success) {
              if (res.data.message == "Expired token") {
                localStorage.removeItem("tokenData");
                Swal.fire({
                  title: "Oturum hatası!",
                  text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                  icon: "warning",
                  confirmButtonText: "Tamam",
                  timer: 1300,
                });
                setTimeout(() => {
                  this.$router.push("/");
                }, 500);
                this.$root.navbarType = "login";
              } else {
                Swal.fire({
                  title: "Uyarı",
                  text: res.data.message,
                  icon: "warning",
                  confirmButtonText: "Tamam",
                });
              }
            } else {
              this.$root.isLoading = false;
              Swal.fire({
                title: "Başarılı",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "Tamam",
                timer: 1300
              });
              setTimeout(() => {
                this.$router.push("/subscriptions");
              }, 500);
            }
          })
          .catch((err) => console.log("Profile: ", err));
      }
    },
    getLastBalance: function () {
      var self=this;
      axios
        .get(this.BASE_API_URL + "/Employees/Profile", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            if (res.data.data) {
              this.lastBalance = res.data.data.TotalBalance;
              self.syncAmount();
            }
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
    getCards: function () {
      axios
        .get(this.BASE_API_URL + "/Cards/MyCards", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            this.cards = res.data.data.data;
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
    getAccessZones: function () {
      axios
        .get(this.BASE_API_URL + "/AccessZones/GetAllZones", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            this.accessZones = res.data.data.data;
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
    getApplications: function () {
      axios
        .get(this.BASE_API_URL + "/CafeteriaApplications/GetAllApplications", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            this.applications = res.data.data.data;
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
    getPackage: function () {
      if (this.application != null && this.card != null) {
        this.subscriptionPackage = null;
        this.startDate = null;
        this.dayCount = 0;
        this.dailyPrice = 0;
        this.totalPrice = 0;
        var formData = new FormData();
        formData.append("CafeteriaGroupID", this.card.CafeteriaGroupID);
        formData.append("ApplicationID", this.application);
        axios
          .post(
            this.BASE_API_URL + "/SubscriptionPackages/GetAllForClient",
            formData,
            {
              headers: { Authorization: localStorage.getItem("tokenData") },
            }
          )
          .then((res) => {
            if (!res.data.success) {
              if (res.data.message == "Expired token") {
                localStorage.removeItem("tokenData");
                Swal.fire({
                  title: "Oturum hatası!",
                  text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                  icon: "warning",
                  confirmButtonText: "Tamam",
                  timer: 1300,
                });
                setTimeout(() => {
                  this.$router.push("/");
                }, 500);
                this.$root.navbarType = "login";
              }
            } else {
              this.$root.isLoading = false;
              this.subscriptionPackages = res.data.data.data;
              if (res.data.data.data.length == 0) {
                Swal.fire({
                  title: "Uyarı",
                  text: "Paket bulunamadı",
                  icon: "warning",
                  confirmButtonText: "Tamam",
                });
              }
            }
          })
          .catch((err) => console.log("Profile: ", err));
      }
    },
    changePackage: function () {
      var selected = this.subscriptionPackages.filter(
        (item) => item.Id == this.subscriptionPackage
      );
      this.startDateDisable = false;
      if (selected.length > 0 && selected[0].IsSelectable == 1) {
        this.startDateDisable = true;
        this.startDate = selected[0].SelectableDate;
        this.message = selected[0].Description
        this.getDetail();
      }
    },
    getDetail: function () {
      if (this.startDate != null) {
        var formData = new FormData();
        formData.append("TagCode", this.card.TagCode);
        formData.append("ApplicationID", this.application);
        formData.append("PackageID", this.subscriptionPackage);
        formData.append("StartDate", this.startDate);
        axios
          .post(
            this.BASE_API_URL + "/SubscriptionEvents/GetDetailsForClient",
            formData,
            {
              headers: { Authorization: localStorage.getItem("tokenData") },
            }
          )
          .then((res) => {
            if (!res.data.success) {
              if (res.data.message == "Expired token") {
                localStorage.removeItem("tokenData");
                Swal.fire({
                  title: "Oturum hatası!",
                  text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                  icon: "warning",
                  confirmButtonText: "Tamam",
                  timer: 1300,
                });
                setTimeout(() => {
                  this.$router.push("/");
                }, 500);
                this.$root.navbarType = "login";
              } else {
                Swal.fire(res.data.message);
              }
            } else {
              this.$root.isLoading = false;
              this.totalPrice = res.data.data.TotalPrice;
              this.days = res.data.data.Days;
              this.dailyPrice = res.data.data.DailyPrice;
              this.dayCount = res.data.data.DayCount;
              this.days.forEach(d=>{
                this.selectedDays=[...this.selectedDays,d.date];
              });
            }
          })
          .catch((err) => console.log("Profile: ", err));
      }
    },
    syncAmount: function () {
      var arr = [5, 10, 20, 50, 100, 200];
      this.amountList = arr.filter(
        (item) => this.lastBalance > 0 || item * -1 <= this.lastBalance
      );
      this.amount = this.amountList[0];
    },
    getDisabled: function (day) {
      return this.days.includes(day) ? false : true;
    },
    syncPriceAndDay: function () {
      this.totalPrice= (this.selectedDays.length* (this.dailyPrice.replace(".","")))/100;
      this.dayCount= this.selectedDays.length;
      this.days.forEach(e=>{
         e.selected=this.selectedDays.includes(e.date);
      })
    },
  },
  created() {
    this.$root.isLoading = true;
    this.getLastBalance();
    this.getCards();
    this.getAccessZones();
    this.getApplications();
    document.title = this.$route.name;
  },
  mounted() {
    this.$root.isLoading = false;
  },
};
</script>

<style scoped>
img {
  height: 100px !important;
  width: 100px !important;
  object-fit: cover;
}

body {
  background-color: #f0f2f5 !important;
}

* {
  transition: .3s;
}

.selected-card {
  position: relative;
}

.selected-card>button {
  display: block !important;
}

.card-detail>i {
  position: relative;
  top: -40px;
  font-size: 36px;
  padding: 10px;
  border-radius: 0.25rem;
}

.card-detail>.amountText {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 10px;
}

.card-detail>.amount {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 35px;
}

.card-detail {
  background-color: #fff;
  color: black;
  border-radius: 0.25rem;
}

.fade-enter-active,
.fade-leave-active {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.payAmount {
  background-color: #21d59e;
  padding-top: 20px;
  width: 80px;
  height: 80px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  color: white;
  position: absolute;
  left: -40px;
}

.pay-form-group {
  padding: 5px 60px;
}

button {
  background-color: #21d59e;
  color: white;
}

button:hover {
  background-color: #05ac7a;
  color: white;
}

@media only screen and (max-width: 600px) {
  .pay-form-group {
    padding: 5px 10px;
  }

  .payAmount {
    width: 50px;
    padding-top: 12.5px;
    height: 50px;
    line-height: 12.5px;
    border-radius: 50%;
    left: -25px;
    font-size: 10px !important;
  }

  button {
    font-size: 10px !important;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #21d59e;
}

input:focus+.slider {
  box-shadow: 0 0 1px #21d59e;
}

input:disabled+.slider {
  background-color: #d52121;
}

input:disabled+.slider {
  box-shadow: 0 0 1px #d52121;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
