<template lang="">
    <div>
        <MDBCard text="left" class="col-md-8 col-lg-8 bg-light p-3 mt-3 rounded mx-auto">
            <MDBCardHeader>
                <MDBCardTitle>Ödeme Onayı</MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody class="w-100" v-if="status">
                <MDBCardText>Almak istediğiniz hizmet için ödenmesi gereken tutar {{payForm.amount}} ₺ dir. Onaylıyorsanız ödeme yap butonuna tıklayınız.</MDBCardText>
                <MDBRow class="justify-content-end mt-4">
                    <MDBCol md="4">
                        <form :method="payForm.method || 'POST'" :action="payForm.url">
                            <MDBBtn type="submit" color="primary" class="w-100">Ödeme Yap</MDBBtn>
                            <input type="hidden" v-for="(item, index) in Object.keys(payForm.inputs)" :key="index" :name="item" :value="payForm.inputs[item]">
                        </form>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
            <MDBCardBody class="w-100" v-if="!status">
                <MDBCardText class="alert alert-danger">{{message}}</MDBCardText>
            </MDBCardBody>
        </MDBCard>
    </div>
    </template>
    
<script>
import {
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
} from "mdb-vue-ui-kit";
export default {
    inject: ["BASE_API_URL"],
    components: {
        MDBCardHeader,
        MDBCardBody,
        MDBCardTitle,
        MDBCardText,
        MDBRow,
        MDBCol,
        MDBCard,
        MDBBtn,
    },
    data() {
        return {
            tryCount: 0,
            status: true,
            message: "",
            cards: {},
            modalStatus: true,
            activeTabId1: "ex1-1",
        };
    },
    props: {
        payForm: Object
    },
    methods: {
        setAmount: function (e) {
            this.amount = e;
        },
    },
    created() {
        this.$root.isLoading = true;
        document.title = this.$route.name;
    },
    mounted() {
        this.$root.isLoading = false;
    },
};
</script>
    
<style scoped>
img {
    height: 100px !important;
    width: 100px !important;
    object-fit: cover;
}

body {
    background-color: #f0f2f5 !important;
}

* {
    transition: 1s;
}

.selected-card {
    position: relative;
}

.selected-card>button {
    display: block !important;
}

.card-detail>i {
    position: relative;
    top: -40px;
    font-size: 36px;
    padding: 10px;
    border-radius: 0.25rem;
}

.card-detail>.amountText {
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 10px;
}

.card-detail>.amount {
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 35px;
}

.card-detail {
    background-color: #fff;
    color: black;
    border-radius: 0.25rem;
}

.fade-enter-active,
.fade-leave-active {
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
}
</style>
    