<template>
<div class="group row">
    <div class="col">
        <span class="fw-bold">İşlem No:</span> <span>{{data.SubscriptionEventsID}}</span><br>
        <span class="fw-bold">Tarih:</span> <span>{{data.Day}}</span><br>
    </div>
    <div class="col">
        <div class="row justify-content-evenly">
            <div class="text-center"><span class="fw-bold">Kart No:</span> {{data.TagCode}}</div>
            <div class="text-center"><span class="fw-bold">Paket:</span> {{data.PackageName}}</div>
        </div>
    </div>
    <div class="col">
        <div class="row justify-content-end">
            <div class="text-end"><span class="fw-bold">Bölge:</span> {{data.ZoneName}}</div><br>
            <div class="text-end"><span class="fw-bold">Zaman Dilimi:</span> {{data.ApplicationName}}</div><br>
            <MDBBtn v-if="data.isUsed==1" color="danger" class="p-1">Kullanıldı</MDBBtn>
            <MDBBtn v-if="data.isUsed==0" color="success" class="p-1">Kullanılmadı</MDBBtn>
        </div>
    </div>
</div>
</template>

<script>
import 'jquery/dist/jquery.min.js';
import {
    MDBBtn
} from "mdb-vue-ui-kit";
//Datatable Modules
export default {
    inject: ["BASE_API_URL"],
    components: {
        MDBBtn
    },
    props: {
        data: Object
    },
    mounted() {
    },
}
</script>

<style scoped>
.group{
    border: 1px solid #e6e6e6b2;
    padding:.25rem .5rem;
    margin-bottom: 2px;
    border-radius: 3px;
}
@media only screen and (max-width: 600px) {
  .group>* {
    font-size: 10px;
  }
  .justify-content-end button{
      padding: 1px !important;
  }
}

</style>
