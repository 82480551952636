<template>
  <div>
    <!--<MDBNavbar v-if="navbarType=='login'" expand="lg" light bg="light" container>
    <MDBNavbarToggler
      target="#navbarCenterAlignExample"
      @click="collapse1 = !collapse1"
    ></MDBNavbarToggler>
    <MDBCollapse id="navbarCenterAlignExample" v-model="collapse1">
      <MDBNavbarNav right class="mb-2 mb-lg-0">
         <MDBNavbarItem to="/"> Anasayfa </MDBNavbarItem> 
        <MDBNavbarItem to="/"> Giriş </MDBNavbarItem>
      </MDBNavbarNav>
    </MDBCollapse>
  </MDBNavbar>-->
    <MDBNavbar v-if="navbarType == 'dashboard'" expand="lg" light bg="light" container>
      <img src="../assets/home_logo_afyon.png" class="m-2" style="height: 40px" alt="" />
      <MDBNavbarToggler target="#navbarCenterAlignExample" @click="collapse2 = !collapse2"></MDBNavbarToggler>
      <MDBCollapse id="navbarCenterAlignExample" v-model="collapse2">
        <MDBNavbarNav right class="mb-2 mb-lg-0">
          <MDBNavbarItem to="/dashboard"> Kullanıcı Paneli </MDBNavbarItem>
          <MDBNavbarItem to="/payments"> Ödemelerim </MDBNavbarItem>
          <MDBNavbarItem v-if="SubscriptionStatus == 'true'" to="/subscriptions"> Abonelikler </MDBNavbarItem>
          <MDBNavbarItem to="/cards"> Kartlarım </MDBNavbarItem>
          <!-- <MDBNavbarItem to="/hes"> Hes </MDBNavbarItem> -->
          <MDBNavbarItem v-if="AuthType == 'Custom'" to="/profile"> Profilim </MDBNavbarItem>
          <MDBNavbarItem to="#" @click="logout"> Çıkış </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  </div>
</template>
<script>
import axios from "axios";
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
} from "mdb-vue-ui-kit";
import { ref } from "vue";

export default {
  inject: ["BASE_API_URL"],
  components: {
    MDBNavbar,
    MDBNavbarToggler,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBCollapse,
  },
  props: {
    navbarType: String,
  },
  setup() {
    const collapse1 = ref(false);
    const collapse2 = ref(false);

    return {
      collapse1,
      collapse2,
      AuthType: 'Custom',
      SubscriptionStatus: "true"
    };
  },
  mounted() {
    var obj = this;
    axios
      .get(this.BASE_API_URL + "/Triggers/GetEnv")
      .then((res) => {
        obj.AuthType = res.data["SETTING.AuthType"];
        obj.SubscriptionStatus = res.data["SETTING.SubscriptionStatus"];
        this.$root.isLoading = false;
      })
      .catch((err) => console.log("Login: ", err));
    this.$root.isLoading = false;
  },
  methods: {
    logout() {
      localStorage.removeItem("tokenData");
      window.location.href = "/";
    },
  },
};
</script>