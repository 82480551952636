<template lang="">
<div>
    <MDBCard text="center" class="col-md-8 col-lg-6 bg-light p-3 mt-3 rounded mx-auto">
        <MDBCardHeader>
            <img :src="image" class="rounded-circle mb-2" alt="" />
            <MDBCardTitle>{{Name}} {{SurName}}</MDBCardTitle>
            <MDBCardTitle class="text-muted">{{Department}}</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <MDBRow class="p-0 needs-validation">
                <KeyValueTable :data="userDetail"></KeyValueTable>
            </MDBRow>

        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import KeyValueTable from "../components/Tables/KeyValueTable.vue";
import {
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCard,
} from "mdb-vue-ui-kit";
export default {
  inject: ["BASE_API_URL"],
  components: {
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBRow,
    MDBCard,
    KeyValueTable,
  },
  data() {
    return {
      tryCount: 0,
      image: "/avatar.png",
      Name: null,
      SurName: null,
      Department: null,
      userDetail: {},
      Balance: 0,
    };
  },
  methods: {
    getProfile: function () {
      this.tryCount++;
      //this.userData = localStorage.getItem("tokenData");
      axios
        .get(this.BASE_API_URL + "/Employees/Profile", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          this.$root.isLoading = false;
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else if (res.data.reset) {
            this.$router.push("/update-password");
          } else {
            this.Name = res.data.data.Name;
            this.SurName = res.data.data.SurName;
            if (
              res.data.data.PictureID &&
              res.data.data.PictureID.split('"').length > 0 &&
              res.data.data.PictureID.includes("http")
            ){
              this.image = res.data.data.PictureID.split('"')[1];
            }
            else if (
              res.data.data.PictureID &&
              res.data.data.PictureID.includes("png")
            ){
              this.image=this.BASE_API_URL+"/images/"+ res.data.data.PictureID;
            }
            if (res.data.data.DepartmentID)
              this.Department = res.data.data.DepartmentID.text;
            var data = Object.assign({},res.data.data);
            delete data.PictureID;
            delete data.DepartmentID;
            delete data.TotalCredit;
            delete data.Name;
            delete data.SurName;
            var fields = res.data.fields;
            fields.forEach((element) => {
              if (data[element.Field] != undefined) {
                data[element.Name] = data[element.Field];
                delete data[element.Field];
              }
            });
            this.userDetail = data;
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
  },
  created() {
    this.$root.navbarType = "dashboard";
    this.$root.isLoading = true;
    document.title = this.$route.name;
    this.getProfile();
  },
  mounted() {},
};
</script>

<style scoped>
img {
  height: 100px !important;
  width: 100px !important;
  object-fit: cover;
}
</style>
