<template>
  <div class="vld-parent">
    <Loading
      :active="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></Loading>
    <Navbar :navbarType="navbarType"> </Navbar>
    <router-view v-slot="slotProps">
      <transition name="route" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </div>
</template>
<script>
import Navbar from "./components/Navbar.vue";
import Loading from "vue-loading-overlay";
export default {
  components: {
    Navbar,
    Loading,
  },
  data() {
    return {
      isLoading:true,
      fullPage:true,
      navbarType: "login",
    };
  },
  mounted() {
    if(localStorage.getItem("tokenData")!=null){
      this.navbarType="dashboard"
    }
    this.$nextTick((from,to) => {
      console.log(from);
      console.log(to);
    });
  },
};
</script>
<style lang="css">
  body{
    background-color: #F0F2F5!important;
  }
.route-enter-from,
.route-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.route-enter-active,
.route-leave-active {
  transition: all 0.3s ease;
}
.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (max-width: 600px) {
*{
  font-size: 12px !important;
}
}
</style>