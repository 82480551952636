<template lang="">
<div>
    <MDBCard text="center" class="col-md-8 col-lg-6 bg-light p-3 mt-3 rounded mx-auto">
        <MDBCardHeader>
            <MDBCardTitle>Şifre Yenileme</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <MDBRow tag="form" class="p-0 needs-validation" validate @submit.prevent="updatePassword">
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Eski Şifre" type="password" v-model="oldpassword" validationEvent="input" invalidFeedback="Kontrol ediniz" required minLength="4" />
                </MDBCol>
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Yeni Şifre" type="password" v-model="password" validationEvent="input" invalidFeedback="Kontrol ediniz" required minLength="4" />
                </MDBCol>
                <MDBCol md="12" class="p-1 mb-2">
                    <MDBInput label="Yeni Şifre Tekrar" type="password" v-model="repassword" invalidFeedback="Kontrol ediniz" required validationEvent="input" minLength="4" />
                </MDBCol>
                <MDBRow class="justify-content-end p-0 m-0">
                    <MDBCol md="4" class="p-1">
                        <MDBBtn color="primary" class="w-100" type="submit" >Onayla</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBRow>

        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import {
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBInput,
  MDBBtn,
} from "mdb-vue-ui-kit";
export default {
  inject: ["BASE_API_URL"],
  components: {
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBRow,
    MDBCard,
    MDBCol,
    MDBInput,
    MDBBtn,
  },
  data() {
    return {
      tryCount: 0,
      oldpassword: null,
      password: null,
      repassword: null,
    };
  },
  methods: {
    updatePassword: function () {
      if (this.repassword == this.password) {
        this.tryCount++;
        var formdata = new FormData();
        formdata.append("OldPassword", this.oldpassword);
        formdata.append("Password", this.password);
        axios
          .post(this.BASE_API_URL + "/Employees/ResetPassword", formdata, {
            headers: { Authorization: localStorage.getItem("tokenData") },
          })
          .then((res) => {
            this.$root.isLoading = false;
            if (res.data.success == true) {
              Swal.fire({
                title: "Başarılı!",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
            } else {
              Swal.fire({
                title: "Hata!",
                text: res.data.message,
                icon: "error",
                confirmButtonText: "Tamam",
              });
            }
          })
          .catch((err) => {
            this.$root.isLoading = false;
            console.log("Set Password: ", err);
          });
      } else {
        Swal.fire({
          title: "Hata!",
          text: "Şifreler aynı olmalıdır",
          icon: "error",
          confirmButtonText: "Tamam",
        });
      }
    },
  },
  created() {
    this.$root.isLoading = false;
    this.$root.navbarType = "login";
    document.title = this.$route.name;
  },
  mounted() {},
};
</script>

<style scoped>
img {
  height: 100px !important;
  width: 100px !important;
  object-fit: cover;
}
</style>
