<template>
<div class="group row">
    <div class="col">
        <span class="fw-bold">İşlem No:</span> 
        <span v-if="data.PaymentId==0">{{data.CafeteriaEventID}}</span>
        <span v-if="data.PaymentId!=0">{{data.PaymentId}}</span><br>
        <span class="fw-bold">Tarih:</span> <span>{{data.EventTime.substring(0,16)}}</span><br>
        <span class="fw-bold">Ödeme Tipi:</span> <span v-if="data.PaymentType!=null">{{data.PaymentType}}</span>
        <span v-if="data.PaymentType==null">-</span>
    </div>
    <div class="col">
        <div class="row justify-content-evenly">
            <div v-if="data.CafeteriaGroupName!=null" class="text-center"><span class="fw-bold">Kafeterya Grup:</span> {{data.CafeteriaGroupName}}</div>
            <div v-if="data.CafeteriaGroupName==null" class="text-center"><span class="fw-bold">Kafeterya Grup:</span> -</div><br>
            <div class="text-center"><span class="fw-bold">Kart No:</span> {{data.TagCode}}</div>
        </div>
    </div>
    <div class="col">
        <div class="row justify-content-end">
            <div class="text-end"><span class="fw-bold">Mekan:</span> {{data.Location}}</div><br>
            <MDBBtn v-if="data.Amount>0 && data.Status==1" color="success"  class="col-6 p-1">{{data.Amount}} ₺</MDBBtn>
            <MDBBtn v-if="data.Amount<=0 && data.Status==1" color="danger" class="col-6 p-1">{{data.Amount}} ₺</MDBBtn>
            <MDBBtn v-if="data.Amount>0 && data.Status==0" color="danger" class="col-6 p-1">{{data.Amount}} ₺</MDBBtn>
            <MDBBtn v-if="data.Status==-1" v-on:click="checkPay(data)" color="warning" class="col-6 p-1">Yüklemeyi Tamamla ({{data.Amount}} ₺)</MDBBtn>
        </div>
    </div>
</div>
</template>

<script>
import 'jquery/dist/jquery.min.js';
import axios from "axios";
import {
    MDBBtn
} from "mdb-vue-ui-kit";
import Swal from 'sweetalert2';
//Datatable Modules
export default {
    inject: ["BASE_API_URL"],
    components: {
        MDBBtn
    },
    props: {
        data: Object,
        fun: Function
    },
    methods: {
        checkPay: function(data){
            var formData = new FormData();
            formData.append("Id", data.PaymentId);
            axios
                .post(this.BASE_API_URL + "/Banks/CheckPayment", formData, {
                headers: { Authorization: localStorage.getItem("tokenData") },
                })
                .then((res) => {
                if (!res.data.success) {
                    Swal.fire({
                        title: "Uyarı!",
                        text: res.data.message,
                        icon: "warning",
                        confirmButtonText: "Tamam"
                    });
                } else {
                    this.payVisible=true;
                    Swal.fire({
                        title: "Başarılı!",
                        text: res.data.message,
                        icon: "success",
                        confirmButtonText: "Tamam"
                    });
                    this.fun();
                }
                })
                .catch((err) => console.log("Profile: ", err));
                }
    },
    mounted() {
    },
}
</script>

<style scoped>
.group{
    border: 1px solid #e6e6e6b2;
    padding:.25rem .5rem;
    margin-bottom: 2px;
    border-radius: 3px;
}
@media only screen and (max-width: 600px) {
  .group>* {
    font-size: 12px;
  }
  .justify-content-end button{
      padding: 1px !important;
  }
}

</style>
