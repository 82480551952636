<template lang="">
<div>
    <MDBCard text="left" class="col-md-8 col-lg-8 bg-light p-3 mt-3 rounded mx-auto">
        <MDBCardHeader>
            <MDBCardTitle class="hdr">Ödemelerim</MDBCardTitle>
            <MDBRow :cols="['1','md-2']" class="g-4 justify-content-between">
                <MDBCol class="col-md-9">
                    <!-- <MDBCardText>Toplam Bakiye: {{balance}} ₺</MDBCardText> -->
                    <MDBCardText>Hesap Bakiyeniz: {{lastBalance}} ₺</MDBCardText>
                    <MDBCardText>*Yüklemeleriniz 15dk içerisinde sisteme aktarılacaktır.
                      <br>*Eksi bakiyeli hesaplar için minimum yükleme tutarı borç tutarından fazla olmalıdır.
                      <br>*Teknik iptal olan sanal pos ödemeleriniz ertesi iş gününde hesabınıza iade edilecektir. İade olmayan tutarlar için sistem yetkilisine "İşlem Numarasını" iletiniz.
                      <br><span class="text-danger"> *Sanalpos yükleme işlemleri 00:00 - 23:30 arasında yapılabilmektedir.</span>
                      </MDBCardText>
                    
                </MDBCol>
                <MDBCol class="col-md-3 p-0 d-flex align-items-end justify-content-end">
                    <!-- <router-link to="/add-subscription" class="btn btn-primary" style="display:inline-block !important;">Abone Paketi Yükle</router-link> -->
                    <router-link to="/add-balance" class="btn btn-primary" style="display:inline-block !important;">Yükleme Yap</router-link>
                </MDBCol>
            </MDBRow>
        </MDBCardHeader>
        <MDBCardBody class="w-100">
            
            <div class="row pb-2">
                <label>E-Cüzdan hesabınızı seçiniz?</label>
                <select class="form-control" v-model="account">
                    <option v-for="item in accounts" :key="item" :value="item.ID">{{item.AccountName}}</option>
                </select>
            </div>
            <div class="row pb-2">
                <label>Rapor Süresi</label>
                <select class="form-control" v-model="sinceReport">
                  <option value="1">1 Ay</option>
                  <option value="3">3 Ay</option>
                  <option value="6">6 Ay</option>
                </select>
            </div>
            <div class="row pb-2 d-flex justify-content-end align-items-center">
              <MDBBtn color="success" class="col-md-2" type="button" @click="getPayments()">Raporu Getir</MDBBtn>
            </div>
            <div v-for="item in transactions" :key="item">
                <PaymentDetails :data="item" :fun="getPayments"> </PaymentDetails>
            </div>
        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import PaymentDetails from "../components/Tables/PaymentDetails.vue";
import {
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBCard,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-vue-ui-kit";
export default {
  inject: ["BASE_API_URL"],
  components: {
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBBtn,
    MDBCard,
    MDBCardText,
    MDBRow,
    MDBCol,
    PaymentDetails,
  },
  data() {
    return {
      transactions: [],
      balance: "...",
      lastBalance: "...",
      credit: "...",
      accounts: [],
      account: 1,
      sinceReport: 1
    };
  },
  methods: {
    getAccounts: function () {
      axios
        .get(this.BASE_API_URL + "/CafeteriaAccounts/GetAll", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            if (res.data.data) {
              this.accounts = res.data.data.data;
              this.syncAmount();
            }
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
    getPayments: function () {
      this.tryCount++;
      axios
        .get(this.BASE_API_URL + "/CafeteriaEvents/GetMyActivities?AccountId=" + this.account + "&StartDate=" + this.sinceReport, {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            if (res.data.data) {
              this.transactions = res.data.data;
            }
          }
        })
        .catch((err) => console.log("Profile: ", err));

      axios
        .get(this.BASE_API_URL + "/Employees/Profile", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.balance = res.data.data.TotalBalance;
            this.credit = res.data.data.TotalCredit;
          }
        })
        .catch((err) => console.log("Profile: ", err));
      this.getLastBalance();
    },
    getLastBalance: function () {
      axios
        .get(this.BASE_API_URL + "/Employees/GetLastBalance?account=" + this.account, {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            if (res.data) {
              this.lastBalance = res.data.data;
              this.syncAmount();
            }
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
    continueProcess: function (id) {
      alert(id);
    },
  },
  created() {
    this.$root.isLoading = true;
    document.title = this.$route.name;
    this.getAccounts();
  },
  mounted() { this.getLastBalance(); },
};
</script>

<style scoped>
img {
  height: 100px !important;
  width: 100px !important;
  object-fit: cover;
}

.table>* * {
  padding: 5px !important;
  margin: 0px !important;
}

@media only screen and (max-width: 600px) {
  * {
    font-size: 12px;
  }

  .btn {
    padding: 5px 8px !important;
  }
}
</style>
