<template lang="">
    <div>
    <MDBCard text="left" class="col-md-8 col-lg-8 bg-light p-3 mt-3 rounded mx-auto">
        <MDBCardHeader>
            <MDBCardTitle>Yükleme İşlem Sonucu</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody class="w-100">
        <div :class="getClassMessage()">{{this.message}}</div>
        <router-link to="/dashboard" class="btn btn-primary">Ana Sayfaya Dön</router-link>
        </MDBCardBody>
    </MDBCard>
    </div>
</template>
<script>
import { useRoute } from "vue-router";
import {
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBCard
} from "mdb-vue-ui-kit";
export default {
  components: {
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBCard
  },
  data() {
    return {
      type: "",
      message: "",
    };
  },
  methods:{
    getClassMessage(){
      if(this.type=="Success"){
        return "alert alert-success";
      }
        return "alert alert-danger";
    }
  },
  created() {
    document.title = this.$route.name;
    const route = useRoute();
    this.type = route.params.type;
    this.message = decodeURIComponent(escape(atob(route.params.message)));
    this.$root.isLoading = false;
  },
};
</script>
<style lang="">
</style>