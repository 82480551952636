<template lang="">
<div>
    <MDBCard v-if="payVisible==false" text="left" class="col-md-8 col-lg-8 bg-light p-3 mt-3 rounded mx-auto" style="max-width:80%">
        <div class="card-header h4">Ödeme Bilgileri</div>
        <div class="card-body">
        <div class="pay-form-group">
            <div class="payAmount"><div>Bakiye</div><div>{{lastBalance}} ₺</div></div>
            <div class="text-danger" v-if="lastBalance<0">Minimum eksi bakiyeniz kadar yükleme yapabilirsiniz.</div>
            <div class="form-group">
                <div class="row">
                    <div class="col">
                        <label>Hesap Seçiniz</label>
                        <select class="form-control" v-model="account" @change="getLastBalance">
                            <option v-for="item in accounts" :key="item" :value="item.ID">{{item.AccountName}}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <label>Yükleme tutarını seçiniz</label>
                        <select class="form-control" v-model="amount">
                            <option v-for="item in amountList" :key="item">{{item}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group mt-3 row justify-content-end" style="padding:0px 13px">
                  <button class="btn col-sm-12 col-md-6" v-on:click="addBalance()"> Yükle</button>
            </div>
        </div>
        </div>
        
    </MDBCard>
    <PayBalanceWithCard v-if="payVisible" :payForm="payForm"></PayBalanceWithCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { maska } from "maska";
import { MDBCard } from "mdb-vue-ui-kit";
import PayBalanceWithCard from './PayBalance.vue';
export default {
  inject: ["BASE_API_URL"],
  directives: { maska },
  components: {
    MDBCard,
    PayBalanceWithCard
  },
  data() {
    return {
      tryCount: 0,
      modalStatus: true,
      lastBalance: 0,
      amount: 5,
      accounts:[],
      account:null,
      amountList:[5,10,20,30,40,50,60,70,80,90,100,150,200,300,500,1000],
      payForm:{},
      payVisible:false,
    };
  },
  methods: {
    addBalance: function () {
      this.$root.isLoading = true;
      this.tryCount++;
      var formData = new FormData();
      formData.append("Amount", this.amount);
      formData.append("AccountId", this.account);
      axios
        .post(this.BASE_API_URL + "/Banks/CreatePaymentForClient", formData, {
          headers: { Authorization: localStorage.getItem("tokenData") },
        })
        .then((res) => {
          this.$root.isLoading = false;
          if (!res.data.success) {
            if (res.data.messages == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                    this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
            else{
              Swal.fire({
                title: "Hata!",
                text: res.data.message,
                icon: "warning",
                confirmButtonText: "Tamam"
              });
            }
          } else {
            this.payVisible=true;
            this.payForm=res.data.data;
          }
        })
        .catch((err) => {
          
    this.$root.isLoading = false;
          console.log("Profile: ", err)
    });
    },
    getLastBalance: function () {
      this.$root.isLoading = true;
      axios
          .get(this.BASE_API_URL + "/Employees/GetLastBalance?account="+this.account, {
              headers: {
                  Authorization: localStorage.getItem("tokenData"),
              },
          })
        .then((res) => {
          this.$root.isLoading = false;
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            if (res.data) {
              this.lastBalance = res.data.data;
              this.syncAmount();
            }
          }
        })
        .catch((err) => {
          console.log("Profile: ", err)
          this.$root.isLoading = false;
        });
    },
    getAccounts: function () {
      this.$root.isLoading = true;
      axios
          .get(this.BASE_API_URL + "/CafeteriaAccounts/GetAll", {
              headers: {
                  Authorization: localStorage.getItem("tokenData"),
              },
          })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            if (res.data.data) {
              this.accounts = res.data.data.data;
              this.syncAmount();
            }
          }
        })
        .catch((err) => {
          this.$root.isLoading = false;
          console.log("Profile: ", err)
          });
    },
    syncAmount: function(){
      var arr=[5,10,20,30,40,50,60,70,80,90,100,150,200,300,500,1000];
      this.amountList=arr.filter(item=>this.lastBalance>0||item*-1<=this.lastBalance);
      this.amount=this.amountList[0];
    }
  },
  created() {
    this.$root.isLoading = true;
    this.getAccounts();
    document.title = this.$route.name;
  },
  mounted() {
    this.$root.isLoading = false;
  },
};
</script>

<style scoped>
img {
  height: 100px !important;
  width: 100px !important;
  object-fit: cover;
}

body {
  background-color: #f0f2f5 !important;
}

* {
  transition: 1s;
}

.selected-card {
  position: relative;
}

.selected-card > button {
  display: block !important;
}

.card-detail > i {
  position: relative;
  top: -40px;
  font-size: 36px;
  padding: 10px;
  border-radius: 0.25rem;
}

.card-detail > .amountText {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 10px;
}

.card-detail > .amount {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 35px;
}

.card-detail {
  background-color: #fff;
  color: black;
  border-radius: 0.25rem;
}

.fade-enter-active,
.fade-leave-active {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}
.payAmount {
  background-color: #21d59e;
  padding-top: 20px;
  width: 80px;
  height: 80px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  color: white;
  position: absolute;
  left: -40px;
}
.pay-form-group {
  padding: 5px 60px;
}
button {
  background-color: #21d59e;
  color: white;
}
button:hover {
  background-color: #05ac7a;
  color: white;
}
@media only screen and (max-width: 600px) {
  .pay-form-group {
    padding: 5px 10px;
  }
  .payAmount{
    width: 50px;
    padding-top: 12.5px;
    height: 50px;
    line-height: 12.5px;
    border-radius: 50%;
    left: -25px;
    font-size: 10px !important;
  }
  button {
    font-size: 10px !important;
  }
}
</style>
