<template lang="">
<div>
    <MDBCard text="center" class="col-md-8 col-lg-6 bg-light p-3 mt-3 rounded mx-auto">
        <MDBCardHeader>
            <img :src="image" class="rounded-circle mb-2" alt="" />
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <p v-if="Object.keys(userDetail).length==0">Güncelleme yetkiniz bulunmamaktadır.</p>
            <MDBRow tag="form" class="p-0 needs-validation" validate @submit.prevent="updateProfile">
                <MDBCol md="12" class="p-1 mb-2"  v-for="item in fields" :key="item">
                    <MDBInput v-if="item.Type!='file'" :label="item.Name" :type="item.Type" v-model="userDetail[item.Field]" validationEvent="input" invalidFeedback="Kontrol ediniz" />
                    <MDBFile v-if="item.Type=='file'" accept="image/*" @change="handleImage" :label="item.Name" :type="item.Type" v-model="userDetail[item.Field]" validationEvent="input" invalidFeedback="Kontrol ediniz" />
                </MDBCol>
                <MDBRow class="justify-content-end p-0 m-0">
                    <MDBCol md="4" class="p-1">
                        <MDBBtn color="primary" class="w-100" type="submit"  v-if="Object.keys(userDetail).length>0" >Güncelle</MDBBtn>
                    </MDBCol>
                </MDBRow>
            </MDBRow>

        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import {
    MDBCardHeader,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBInput,
    MDBFile,
    MDBCard,
} from "mdb-vue-ui-kit";
export default {
    inject: ["BASE_API_URL"],
    components: {
        MDBCardHeader,
        MDBCardBody,
        MDBRow,
        MDBCol,
        MDBBtn,
        MDBInput,
        MDBFile,
        MDBCard
    },
    data() {
        return {
            tryCount: 0,
            image:"/avatar.png",
            userDetail: {},
            fields:[]
        };
    },
    methods: {
        getProfile: function () {
            this.tryCount++;
            //this.userData = localStorage.getItem("tokenData");
            axios
                .get(this.BASE_API_URL + "/Employees/EditProfile", {
                    headers: {
                        Authorization: localStorage.getItem("tokenData"),
                    },
                })
                .then((res) => {
                    this.$root.isLoading = false;
                    if (!res.data.success) {
                        if (res.data.messages == "Expired token") {
                            localStorage.removeItem("tokenData");
                            Swal.fire({
                                title: "Oturum hatası!",
                                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                                icon: "warning",
                                confirmButtonText: "Tamam",
                                timer: 1300,
                            });
                            setTimeout(() => {
                                    this.$router.push("/");
                            }, 500);
                            this.$root.navbarType = "login";
                        }
                    } else {
                        var data=res.data.data;
                        var fields=res.data.fields;
                        if(res.data.data.PictureID&&res.data.data.PictureID.split('"').length>0){
                            this.image = res.data.data.PictureID.split('"')[1];
                            delete data.PictureID;
                        }
                        this.fields=fields;
                        this.userDetail = data;
                    }
                })
                .catch((err) => console.log("Profile: ", err));
        },
        handleImage(e){
            const selectedImage= e.target.files[0];
            this.createBase64(selectedImage);
        },
        createBase64(file){
            const reader= new FileReader();

            reader.onload = (e)=>{
                this.userDetail.PictureID="data:image/png;base64,"+btoa(e.target.result);
            };
            reader.readAsBinaryString(file);
        },
        updateProfile(e) {
            this.$root.isLoading = true;
            e.target.classList.add("was-validated");
            var bodyFormData = new FormData();
            console.log(this.userDetail);
            Object.keys(this.userDetail).forEach(element=>{
                bodyFormData.append(element, this.userDetail[element]);
            });
            axios
                .post(
                    this.BASE_API_URL + "/Employees/SetProfile", 
                    bodyFormData, 
                    {
                    headers: {
                        Authorization: localStorage.getItem("tokenData"),
                        },
                })
                .then((res) => {
                    this.$root.isLoading = false;
                    if (res.data.success) {
                        Swal.fire({
                            title: "Başarılı!",
                            text: res.data.message,
                            icon: "success",
                            confirmButtonText: "Tamam",
                            timer: 1300,
                        });
                    } else {
                        Swal.fire({
                            title: "Hata!",
                            text: res.data.message,
                            icon: "error",
                            confirmButtonText: "Tamam"
                        });
                    }
                })
                .catch((err) => console.log("New Account: ", err));
        },
    },
    created() {
        this.$root.isLoading = true;
        document.title = this.$route.name;
        this.getProfile();
    },
    mounted() {},
};
</script>

<style scoped>
img {
    height: 100px !important;
    width: 100px !important;
    object-fit: cover;
}
</style>
