<template lang="">
<div>
    <MDBCard text="left" class="col-md-8 col-lg-8 bg-light p-3 mt-3 rounded mx-auto">
        <MDBCardHeader>
            <MDBCardTitle>Kartlarım</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <MDBRow class="g-4">
                <MDBCol class="col-8 col-md-6 col-lg-3 mx-auto" v-if="qrStatus&&qrValue!=''">
                    <qrcode-vue :value="qrValue" :size="qrSize" level="H" />
                </MDBCol>
            </MDBRow>
            <MDBRow  class="g-4">
            <div class="table-responsive">
                <table class="table table-hover table-striped table-bordered table-datatable">
                    <thead>
                        <tr>
                            <th>Kart Id</th>
                            <th>Kafeterya Grup</th>
                            <th>Kart UID</th>
                            <th>Açıklama</th>
                            <th>Durum</th>
                            <th v-if="QrViewStatus=='true'">Qr</th>
                            <th>İşlem</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="card in cards" :key="card">
                            <td>{{card.CardID}}</td>
                            <td>{{card.CafeteriaGroupName??'Tanımlı değil'}}</td>
                            <td>{{card.CardUID}}</td>
                            <td>{{card.CardDesc}}</td>
                            <td>{{card.Status.text}}</td>
                            <td v-if="QrViewStatus=='true'">
                                <MDBBtn color="primary" v-on:click="gotoQrPage(card.CardID)">Qr</MDBBtn>
                            </td>
                            <td>
                                <MDBBtn v-if="card.Status.id==1" color="danger" v-on:click="closeCard(card.CardID)">Kapat</MDBBtn>
                                <div v-if="card.Status.id!=1">Kapalı</div>
                                <MDBBtn v-if="card.Status.id!=1&&false" color="primary" v-on:click="openCard(card.CardID)">Aç</MDBBtn>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </MDBRow>
        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import {
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
} from "mdb-vue-ui-kit";
export default {
  inject: ["BASE_API_URL"],
  components: {
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
  },
  data() {
    return {
      tryCount: 0,
      cards: {},
      modalStatus: true,
      QrViewStatus:true,
      qrStatus: false,
      qrValue: "",
      qrSize: 250,
      cardDetailVisible: {
        1: false,
        2: false,
      },
    };
  },
  methods: {
    setDataTable: function () {
      $(".table-datatable").DataTable({
        language: {
          url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Turkish.json",
        },
      });
    },
    getCards: function () {
      this.tryCount++;
      axios
        .get(this.BASE_API_URL + "/Cards/MyCards", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            this.cards = res.data.data.data;
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
    clickCard: function (e) {
      //e.target.parentNode.classList.add();
      var el = this.findAncestor(e.target, "col-md-6");
      el.parentElement.children.forEach((element) => {
        element.classList.remove("selected-card");
      });
      Object.keys(this.cardDetailVisible).forEach((element) => {
        if (element == el.dataset.sel) this.cardDetailVisible[element] = true;
        else this.cardDetailVisible[element] = false;
      });
      el.classList.add("selected-card");
    },
    openCard: function (id) {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Kartınızı kullanıma açacaksınız onaylıyor musunuz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, Kartı Kullanıma Aç",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get(this.BASE_API_URL + "/Cards/ChangeStatus/" + id, {
              headers: {
                Authorization: localStorage.getItem("tokenData"),
              },
            })
            .then((res) => {
              if (res.data.success) {
                Swal.fire(
                  "Açıldı!",
                  id + " Numaralı kartınız kullanıma açılmıştır.",
                  "success"
                );
                this.cards.forEach((item) => {
                  if (item.CardID == id) item.Status.id = 1;
                });
              } else {
                Swal.fire(
                  "İşlem Yapılamadı!",
                  "Bir hata ile karşılaşıldı.",
                  "warning"
                );
              }
            });
        }
      });
    },
    closeCard: function (id) {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Kartınız kullanıma kapatılacaktır! Onaylıyor musunuz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, Kartı Kapat",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get(this.BASE_API_URL + "/Cards/ChangeStatus/" + id, {
              headers: {
                Authorization: localStorage.getItem("tokenData"),
              },
            })
            .then((res) => {
              if (res.data.success) {
                Swal.fire(
                  "Kapatıldı!",
                  id + " Numaralı kartınız kullanıma kapatılmıştır.",
                  "success"
                );
                this.cards.forEach((item) => {
                  if (item.CardID == id) item.Status.id = 0;
                });
              } else {
                Swal.fire(
                  "İşlem Yapılamadı!",
                  "Bir hata ile karşılaşıldı.",
                  "warning"
                );
              }
            });
        }
      });
    },
    generateQr: function (id) {
      axios
        .get(this.BASE_API_URL + "/Cards/GetQr/" + id, {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.qrValue = res.data.data;
          } else {
            Swal.fire(
              "İşlem Yapılamadı!",
              "Bir hata ile karşılaşıldı.",
              "warning"
            );
            this.qrValue = "";
          }
        });
    },
    gotoQrPage: function (id) {
      this.$router.push("/cards/qr/" + id);
    },
    getQr: function (id) {
      this.qrStatus = true;
      var obj = this;
      obj.generateQr(id);
      let interval = setInterval(() => {
        if (this.qrStatus != true) {
          clearInterval(interval);
        }
        obj.generateQr(id);
      }, 1000);
    },
    findAncestor: function (el, sel) {
      while ((el = el.parentElement) && !el.classList.contains(sel));
      return el;
    },
  },
  created() {
    this.$root.isLoading = true;
    document.title = this.$route.name;
    this.getCards();
  },
  mounted() {
    this.$root.navbarType = "dashboard";
    var obj = this;
    axios
      .get(this.BASE_API_URL + "/Triggers/GetEnv")
      .then((res) => {
        obj.QrViewStatus = res.data["SETTING.QrViewStatus"];
        this.$root.isLoading = false;
      })
      .catch((err) => console.log("Login: ", err));
  },
};
</script>

<style scoped>
img {
  height: 100px !important;
  width: 100px !important;
  object-fit: cover;
}

body {
  background-color: #f0f2f5 !important;
}

* {
  transition: 1s;
}
.qr-viewer {
}
.selected-card {
  position: relative;
}

.selected-card > button {
  display: block !important;
}

.card-detail > i {
  position: relative;
  top: -40px;
  font-size: 36px;
  padding: 10px;
  border-radius: 0.25rem;
}

.card-detail > .amountText {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 10px;
}

.card-detail > .amount {
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 35px;
}

.card-detail {
  background-color: #fff;
  color: black;
  border-radius: 0.25rem;
}

.fade-enter-active,
.fade-leave-active {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: all 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  * {
    font-size: 12px;
  }

  .table * {
    padding: 5px 8px !important;
    font-size: 10px !important;
  }
}
</style>
