<template lang="">
<div>
    <MDBCard text="left" class="col-md-8 col-lg-8 bg-light p-3 mt-3 rounded mx-auto">
        <MDBCardHeader>
            <MDBCardTitle>HES Durumu</MDBCardTitle>
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <MDBRow  class="g-4">
                <div>
                    <MDBInput label="Hes Kodu" type="text" v-model="hesCode" validationEvent="input" invalidFeedback="Kontrol ediniz" />
                    <MDBRow class="justify-content-end p-0 m-0">
                        <MDBCol md="4" class="p-1">
                            <MDBBtn color="primary" class="w-100" v-on:click="setHesCode()" type="submit">Güncelle</MDBBtn>
                        </MDBCol>
                    </MDBRow>
                </div>
                <hr>
                <KeyValueTable :data="hesDetail"></KeyValueTable>
            </MDBRow>
        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import KeyValueTable from "../components/Tables/KeyValueTable.vue";
//import $ from 'jquery';
import {
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCard,
    MDBBtn
} from "mdb-vue-ui-kit";
export default {
    inject: ["BASE_API_URL"],
    components: {
        MDBCardHeader,
        MDBCardBody,
        MDBCardTitle,
        MDBRow,
        MDBCol,
        MDBInput,
        MDBCard,
        MDBBtn,
        KeyValueTable
    },
    data() {
        return {
            tryCount: 0,
            hesDetail: {},
            hesCode:"",
            identificationNumber:""
        };
    },
    methods: {
        getHesDetails: function () {
            this.tryCount++;
            axios
                .get(this.BASE_API_URL + "/HesReports/GetMyDetails", {
                    headers: {
                        Authorization: localStorage.getItem("tokenData"),
                    },
                })
                .then((res) => {
                    console.log(res);
                    if (!res.data.success) {
                        if (res.data.message == "Expired token") {
                            localStorage.removeItem("tokenData");
                            Swal.fire({
                                title: "Oturum hatası!",
                                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                                icon: "warning",
                                confirmButtonText: "Tamam",
                                timer: 1300,
                            });
                            setTimeout(() => {
                                    this.$router.push("/");
                            }, 500);
                            this.$root.navbarType = "login";
                        }
                        else{
                            Swal.fire({
                                title: "Hata!",
                                text: res.data.message,
                                icon: "warning",
                                confirmButtonText: "Tamam"
                            });
                        }
                        this.$root.isLoading = false;
                    } else {
                        this.$root.isLoading = false;
                        var data=res.data.data
                        this.hesCode=data.HesCode;
                        if(data.IsVaccinated)
                            data.IsVaccinated=(data.IsVaccinated==1)?"Aşı oldunuz.":"Aşı olmadınız.";
                        if(data.IsImmune)
                            data.IsImmune=(data.IsImmune==1)?"Son 6 ay içerisinde korona oldunuz.":"Son 6 ay içerisinde korona olmadınız.";
                        var fields=res.data.fields;
                        fields.forEach(element => {
                            data[element.Name]=data[element.Field];
                            delete data[element.Field];
                        });
                        this.hesDetail = data;
                    }
                })
                .catch((err) => console.log("Profile: ", err));
            
        },
        setHesCode(){
            this.$root.isLoading=true;
            var formData= new FormData();
            formData.append('HesCode',this.hesCode);
            axios
                .post(this.BASE_API_URL + "/HesReports/UpdateMyHesCode",formData, {
                    headers: {
                        Authorization: localStorage.getItem("tokenData"),
                    },
                })
                .then((res) => {
                    if (!res.data.success) {
                        if (res.data.message == "Expired token") {
                            localStorage.removeItem("tokenData");
                            Swal.fire({
                                title: "Oturum hatası!",
                                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                                icon: "warning",
                                confirmButtonText: "Tamam",
                                timer: 1300,
                            });
                            setTimeout(() => {
                                    this.$router.push("/");
                            }, 500);
                            this.$root.navbarType = "login";
                        }
                        else{
                            Swal.fire({
                                title: "Hata!",
                                text: res.data.message,
                                icon: "warning",
                                confirmButtonText: "Tamam"
                            });
                        }
                        this.$root.isLoading = false;
                    } else {
                        this.$root.isLoading = false;
                            Swal.fire({
                                title: "Başarılı!",
                                text: "Hes kodunuz güncellendi.",
                                icon: "success",
                                confirmButtonText: "Tamam",
                                timer: 1300,
                            });
                            this.getHesDetails();
                    }
                })
                .catch((err) => console.log("Profile: ", err));
        }
    },
    created() {
        this.$root.isLoading = true;
        document.title = this.$route.name;
        this.getHesDetails();
    },
    mounted() {
    },
};
</script>

<style scoped>
img {
    height: 100px !important;
    width: 100px !important;
    object-fit: cover;
}

body {
    background-color: #f0f2f5 !important;
}

* {
    transition: 1s;
}

.selected-card {
    position: relative;
}

.selected-card>button {
    display: block !important;
}

.card-detail>i {
    position: relative;
    top: -40px;
    font-size: 36px;
    padding: 10px;
    border-radius: 0.25rem;
}

.card-detail>.amountText {
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 10px;
}

.card-detail>.amount {
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 35px;
}

.card-detail {
    background-color: #fff;
    color: black;
    border-radius: 0.25rem;
}

.fade-enter-active,
.fade-leave-active {
    visibility: hidden;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    transition: all 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
}
</style>
