<template lang="">
<div>
    <MDBCard text="left" class="col-md-8 col-lg-8 bg-light p-3 mt-3 rounded mx-auto">
        <MDBCardHeader>
            <MDBCardTitle style="padding-right:8px;">Abonelikler
                    <!-- <button class="btn btn-primary" v-on:click="disableMessage()" style="display:inline-block !important; float:right;">Paket Satın Al</button> -->
                    <router-link to="/add-subscription" class="btn btn-primary" style="display:inline-block !important; float:right;">Paket Satın Al</router-link>
            </MDBCardTitle>
            <MDBRow class="pb-4">
                <MDBCol class="p-0">
                    <MDBCardText class="p-0">Toplam Bakiye: {{balance}} ₺</MDBCardText>
                </MDBCol>
            </MDBRow>
            <MDBRow class="g-4 justify-content-between p-0" style="padding-left:10px !important; padding-top:10px !important">
                      <div class="row p-0">
                        <div class="col-6">
                            <label>Uygulama Seçiniz</label>
                            <select class="form-control" v-model="application">
                                <option v-for="item in applications" :key="item" :value="item">{{item.ApplicationName}}</option>
                            </select>
                        </div>
                        <div class="col-6">
                            <label>Kart Seçiniz</label>
                            <select class="form-control" v-model="card">
                                <option v-for="item in cards" :key="item" :value="item">{{item.CafeteriaGroupName??'Kafeterya grup yok'}}</option>
                            </select>
                        </div>
                    </div>
                      <div class="row p-0">
                        <div class="col-6">
                            <label>Tarih Seçiniz</label>
                            <select class="form-control" v-model="startDate">
                                <option value="1" selected>Son 1 Ay</option>
                                <option value="3">Son 3 Ay</option>
                                <option value="6">Son 6 Ay</option>
                                <option value="12">Son 1 Yıl</option>
                            </select>
                        </div>
                        <div class="col-6">
                            <label></label>
                            <div style="">
                                <MDBBtn color="primary" class="p-2 col-6" style="float:right" v-on:click="getReport()">Sorgula</MDBBtn>
                            </div>
                        </div>
                    </div>
            </MDBRow>
        </MDBCardHeader>
        <MDBCardBody class="w-100">
            <div v-for="item in transactions" :key="item">
                <SubscriptionDetails :data="item" > </SubscriptionDetails>
            </div>
        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import SubscriptionDetails from "../components/Tables/SubscriptionDetails.vue";
import {
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBCard,
  MDBCardText,
  MDBRow,
  MDBCol,
} from "mdb-vue-ui-kit";
export default {
  inject: ["BASE_API_URL"],
  components: {
    MDBCardHeader,
    MDBCardBody,
    MDBCardTitle,
    MDBBtn,
    MDBCard,
    MDBCardText,
    MDBRow,
    MDBCol,
    SubscriptionDetails,
  },
  data() {
    return {
      transactions: [],
      balance: "...",
      credit: "...",
      startDate: 1,
      cards: [],
      applications: [],
      card: null,
      application: null,
    };
  },
  methods: {
    getPayments: function () {
      this.tryCount++;
      axios
        .get(this.BASE_API_URL + "/Employees/Profile", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.balance = res.data.data.TotalBalance;
            this.credit = res.data.data.TotalCredit;
          }
        })
        .catch((err) => console.log("Profile: ", err));
      axios
        .get(this.BASE_API_URL + "/Cards/MyCards", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            this.cards = res.data.data.data;
          }
        })
        .catch((err) => console.log("Profile: ", err));
      axios
        .get(this.BASE_API_URL + "/CafeteriaApplications/GetAllApplications", {
          headers: {
            Authorization: localStorage.getItem("tokenData"),
          },
        })
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            this.applications = res.data.data.data;
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
    getReport: function () {
      if (this.application == null) {
        Swal.fire("Zaman dilimi seçiniz");
        return;
      }
      if (this.card == null) {
        Swal.fire("Kart seçiniz");
        return;
      }
      axios
        .get(
          this.BASE_API_URL +
            "/SubscriptionEvents/GetMyActivities?StartDate=" +
            this.startDate +
            "&TagCode=" +
            this.card.TagCode +
            "&ApplicationID=" +
            this.application.CafeteriaApplicationID,
          {
            headers: {
              Authorization: localStorage.getItem("tokenData"),
            },
          }
        )
        .then((res) => {
          if (!res.data.success) {
            if (res.data.message == "Expired token") {
              localStorage.removeItem("tokenData");
              Swal.fire({
                title: "Oturum hatası!",
                text: "Oturumunuz sonlanmıştır. Giriş ekranına yönlendiriliyorsunuz..",
                icon: "warning",
                confirmButtonText: "Tamam",
                timer: 1300,
              });
              setTimeout(() => {
                this.$router.push("/");
              }, 500);
              this.$root.navbarType = "login";
            }
          } else {
            this.$root.isLoading = false;
            if (res.data.data.length > 0) {
              this.transactions = res.data.data;
            }
          }
        })
        .catch((err) => console.log("Profile: ", err));
    },
    disableMessage: function() {
      var d = new Date();
      if(d.getDay() ==0 || d.getDay()==6){
        Swal.fire({
          title: "Uyarı!",
          text: "Paket satışları 08.11.2021 tarihinde başlayacaktır.",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
      else{
          this.$router.push("/add-subscription");
      }
    }
  },
  created() {
    this.$root.isLoading = true;
    document.title = this.$route.name;
    this.getPayments();
  },
  mounted() {},
};
</script>

<style scoped>
img {
  height: 100px !important;
  width: 100px !important;
  object-fit: cover;
}
.d-flex > * {
  padding: 5px !important;
  margin: 0px !important;
  font-size: 14px;
}
select {
  font-size: 14px;
}
.pb-4{
  padding-bottom: 1.7rem !important;
}
@media only screen and (max-width: 600px) {
  .btn {
    font-size: 10px;
    padding: 5px 8px !important;
  }
  .d-flex > * {
    padding: 3px !important;
    margin: 0px !important;
    font-size: 10px;
  }
  select {
    font-size: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .btn {
    font-size: 8px !important;
    padding: 3px 5px !important;
  }
  .d-flex > * {
    padding: 3px !important;
    margin: 0px !important;
    font-size: 8px !important;
  }
  select {
    font-size: 8px !important;
  }
  label {
    font-size: 8px !important;
  }
}
</style>
