<template lang="">
<div>
    <MDBCard text="center" class="col-md-8 col-lg-6 bg-light p-3 rounded mx-auto align-items-center" style="margin-top:80px;">
        <MDBCardHeader bg="primary" style=" font-size:30px; color:#fff; margin-left:40px;margin-right:40px; height:80px; transform:translateY(-60px); position:absolute; width:calc(100% - 40px);box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;">
            Yeni Üyelik
        </MDBCardHeader>
        <MDBCardBody class="mt-4 w-100">
            <MDBTabs v-model="activeTabId1">
                <MDBTabNav tabsClasses="mb-3">
                    <MDBTabItem tabId="ex1-1" href="ex1-1">{{formType}}</MDBTabItem>
                </MDBTabNav>
                <MDBTabContent>
                    <MDBTabPane tabId="ex1-1" v-if="formType=='SMS'">
                        <MDBRow tag="form" class="p-0 needs-validation" validate @submit.prevent="smsAccountForm">
                            <MDBCol md="12" class="p-1 mb-2">
                                <MDBInput label="Kimlik Numarası" type="text" v-model="identity" validationEvent="input" invalidFeedback="Kontrol ediniz" required />
                            </MDBCol>
                            <MDBCol md="12" class="p-1 mb-2">
                                <MDBInput label="Telefon Numarası" type="tel" minLength="17" maxLength="17" placeholder="+90(555) 555 5555" :onChange="maskPhone()" v-model="phone" invalidFeedback="Kontrol ediniz" required validationEvent="input" />
                            </MDBCol>
                            <MDBRow class="justify-content-end p-0 m-0">
                                <MDBCol md="4" class="p-1">
                                    <MDBBtn color="primary" class="w-100" type="submit">Üyelik Oluştur</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBRow>
                    </MDBTabPane>
                    <MDBTabPane tabId="ex1-1" v-if="formType=='MAIL'">
                        <MDBRow tag="form" class="p-0 needs-validation" validate @submit.prevent="mailAccountForm">
                            <MDBCol md="12" class="p-1 mb-2">
                                <MDBInput label="Kimlik Numarası" type="text" v-model="identity" validationEvent="input" invalidFeedback="Kontrol ediniz" required />
                            </MDBCol>
                            <MDBCol md="12" class="p-1 mb-2">
                                <MDBInput label="Email" type="email" v-model="email" validationEvent="input" invalidFeedback="Kontrol ediniz" required />
                            </MDBCol>
                            <MDBRow class="justify-content-end p-0 m-0">
                                <MDBCol md="4" class="p-1">
                                    <MDBBtn color="primary" class="w-100" type="submit">Üyelik Oluştur</MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBRow>
                    </MDBTabPane>
                </MDBTabContent>
            </MDBTabs>
        </MDBCardBody>
    </MDBCard>
</div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import {
    MDBInput,
    MDBCardHeader,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
    MDBTabs,
    MDBTabNav,
    MDBTabContent,
    MDBTabItem,
    MDBTabPane,
} from "mdb-vue-ui-kit";
export default {
    title: "NewAccount",
    inject: ["BASE_API_URL"],
    components: {
        MDBInput,
        MDBCardHeader,
        MDBCardBody,
        MDBRow,
        MDBCol,
        MDBCard,
        MDBBtn,
        MDBTabs,
        MDBTabNav,
        MDBTabContent,
        MDBTabItem,
        MDBTabPane,
    },
    data() {
        return {
            email: "",
            identity: "",
            phone: "",
            activeTabId1: "ex1-1",
            formType: "",
        };
    },
    created() {
        document.title = this.$route.name;
        this.$root.isLoading = true;
        axios.post(this.BASE_API_URL + "/Auth/GetContactType").then(res=>{
            this.formType=res.data.formType;
        });
    },
    mounted() {
        this.$root.isLoading = false;
    },
    methods: {
        smsAccountForm(e) {
            e.target.classList.add("was-validated");
            var formData= new FormData();
            formData.append('IdentificationNumber',this.identity);
            formData.append('Phone',this.phone);
            axios
                .post(
                    this.BASE_API_URL + "/Auth/RegisterWithSms", 
                    formData,
                    {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    }
                )
                .then((res) => {
                    this.$root.isLoading = false;
                    if (res.data.success == true) {
                        Swal.fire({
                            title: "Başarılı!",
                            text: res.data.message,
                            icon: "success",
                            confirmButtonText: "Tamam",
                            timer: 1300,
                        });
                        setTimeout(() => {
                            this.$router.push("/set-password/"+this.identity);
                        }, 500);
                        this.$root.navbarType = "login";
                    } else {
                        Swal.fire({
                            title: "Hata!",
                            text: res.data.message,
                            icon: "error",
                            confirmButtonText: "Tamam",
                            timer: 2000,
                        });
                    }
                })
                .catch((err) => console.log("Login: ", err));
        },
        mailAccountForm(e) {
            e.target.classList.add("was-validated");
            var bodyFormData = new FormData();
            bodyFormData.append('IdentificationNumber', this.identity);
            bodyFormData.append('Mail', this.email);
            axios
                .post(
                    this.BASE_API_URL + "/Auth/RegisterWithEmail", 
                    bodyFormData, 
                    {
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": "*",
                    }
                )
                .then((res) => {
                    this.$root.isLoading = false;
                    if (res.data.success) {
                        Swal.fire({
                            title: "Başarılı!",
                            text: res.data.message,
                            icon: "success",
                            confirmButtonText: "Tamam",
                            timer: 1300,
                        });
                        setTimeout(() => {
                            this.$router.push({ path:"/set-password/"+this.identity, query: { mail: this.email }})
                            // this.$router.push("/set-password/"+this.identity);
                        }, 500);
                        this.$root.navbarType = "login";
                    } else {
                        Swal.fire({
                            title: "Hata!",
                            text: res.data.message,
                            icon: "error",
                            confirmButtonText: "Tamam"
                        });
                    }
                })
                .catch((err) => console.log("New Account: ", err));
        },
        maskPhone() {
            var maskedValue = "+__(___)-___-____";
            var mask = this.phone.replace(/[^0-9]/g,'');
            for (var i = 0; i < mask.length; i++) {
                maskedValue= maskedValue.replace("_", mask.charAt(i));
            }
            if(maskedValue.indexOf("_")!="-1"){
                maskedValue= maskedValue.substring(0,maskedValue.indexOf("_"));
            }
            this.phone=maskedValue;
        }
    },
    computed: {
    },
};
</script>

<style lang="">
</style>
